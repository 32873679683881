import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return 'en'
  }

  const lang = navigator && navigator.language && navigator.language.split('-')[0]
  if (!lang) return 'en'

  switch (lang) {
    case 'es':
      return 'es'
    default:
      return 'en'
  }
}

const IndexPage = () => {
  useEffect(() => {
    const urlLang = getRedirectLanguage()

    navigate(`/${urlLang}`)
  }, [])

  return null
}

export default IndexPage
